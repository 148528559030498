/* eslint-disable no-underscore-dangle */
import { io } from 'socket.io-client';

export const CHAT_EVENTS = {
  // Emitter events..........
  VISITOR_HANDSHAKE: 'visitor:handshake',
  VISITOR_INPUT: 'visitor:input',
  PLATFORM_HANDSHAKE: 'platform:handshake',

  // Listener events..........
  RECONNECT: 'reconnect',
  CONNECT_ERROR: 'connect_error',
  AI_RESPONSE: 'ai:response',
  VISITOR_HISTORY: 'visitor:history',
  VISITOR_ERROR: 'visitor:error',
  PLATFORM_INPUT: 'platform:input',
  VISITOR_META_EVENT: 'visitor:metaEvent',
  AI_RESPONSE_STREAM: 'ai:response:stream',
  CSAT_REQUEST: 'csat:request',
};

class ChatSocketAPI {
  constructor() {
    this.socket = null;
  }

  connect = (auth) => {
    this.socket = io(process.env.CHAT_SERVICE_URL, { auth });

    // EVENT LOGGER FOR DEVELOPMENT...........
    if (process.env.ENV !== 'production') this.eventLogger();
  };

  on = (eventName, callback) => {
    if (!this.socket || !eventName) return;

    if (
      this.socket._callbacks !== undefined &&
      typeof this.socket._callbacks[`$${eventName}`] !== 'undefined'
    ) {
      this.socket.off(eventName);
    }

    this.socket.on(eventName, callback);
  };

  handshake = (data) => {
    this.socket.emit(CHAT_EVENTS.VISITOR_HANDSHAKE, data);
  };

  sendMessage = (data) => {
    if (this.socket.connected) {
      this.socket.emit(CHAT_EVENTS.VISITOR_INPUT, data);
    }
  };

  disconnect = () => {
    if (this.socket?.disconnect) this.socket.disconnect();
  };

  eventLogger = () => {
    this.socket.onAny((event, ...args) => {
      // eslint-disable-next-line no-console
      console.log({ event, args });
    });
  };
}

export default new ChatSocketAPI();
