export const DEFAULT_STYLE_PROPS = {
  position: 'right',
  verticalSpacing: 20,
  horizontalSpacing: 20,
  size: 60,
  zIndex: 1050,
};

export const ALLOWED_STYLING_PROPS = Object.keys(DEFAULT_STYLE_PROPS);

export const POPUP_TYPES = {
  video: 'video',
  image: 'image',
  text: 'text',
};
