// import INIT_CHAT_WIDGET from '../constants/data/initChatWidget';

// eslint-disable-next-line import/prefer-default-export
export async function getInitWidgetConfig() {
  //   return INIT_CHAT_WIDGET;
  try {
    const initAPIConfig = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        referrer: window?.location?.href,
      }),
    };

    const response = await fetch(
      `${process.env.CHAT_SERVICE_URL}/init/chat-widget`,
      initAPIConfig
    );

    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    const initConfig = await response.json();

    return initConfig;
  } catch (error) {
    const errorMsg = error?.message || error;
    throw new Error(errorMsg);
  }
}
