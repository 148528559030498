import ChatWidgetComponent from './component';

class ZoidWidgetComponent {
  constructor(props) {
    if (ZoidWidgetComponent.instance) {
      throw new Error(
        'You can only create one instance of ZoidWidgetComponent'
      );
    }

    this.componentWidget = ChatWidgetComponent(props);
  }

  static getInstance = (props) => {
    try {
      if (!ZoidWidgetComponent.instance) {
        ZoidWidgetComponent.instance = new ZoidWidgetComponent(props);
      }

      return Object.freeze(ZoidWidgetComponent.instance);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'Exception occurred in creating ZoidWidgetComponent singleton instance'
      );
    }
  };

  render = (elementId) => {
    this.componentWidget.render(elementId);
  };

  updateProps = (props) => {
    this.componentWidget.updateProps(props);
  };
}

export default ZoidWidgetComponent;
