import { toPixel } from './utils';
import styles from './chat-container.css';

export default class ChatApp {
  constructor() {
    this.ele = null;
    this.initialize();
  }

  initialize = () => {
    // Chat app Iframe message container...
    this.ele = document.createElement('div');
    this.ele.id = 'akin-chat-container';
    this.ele.classList.add(styles.messageContainer);
    this.hide();
  };

  show = () => {
    if (!this.ele) return;
    this.ele.classList.remove(styles.hidden);
  };

  hide = () => {
    if (!this.ele) return;
    this.ele.classList.add(styles.hidden);
  };

  updateStyles = (styleProp) => {
    const isMobileView = window.screen.width <= 600;
    const windowInnerHeight = window?.innerHeight;

    this.ele.style.bottom = toPixel(styleProp.bottom);

    if (styleProp?.zIndex) {
      this.ele.style.zIndex = styleProp.zIndex;
    }

    if (!isMobileView) {
      this.ele.style[styleProp.positionType] = toPixel(styleProp.position);

      // If screen height is less than widget container height, set
      // container position bottom to 15px..
      if (windowInnerHeight < 600) {
        this.ele.style.bottom = '15px';
      } else {
        // Adjusting chat app container height to stay within the window..
        const calContainerHeight = styleProp.bottom + 10;
        this.ele.style.height = `calc(100% - ${calContainerHeight}px)`;
      }
    }
  };

  resetStyles = () => {
    this.ele.style.left = 'unset';
    this.ele.style.right = 'unset';
  };

  destroy = () => {
    if (!this.ele) return;
    this.ele.remove();
  };
}
