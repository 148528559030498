import { toPixel } from './utils';
import styles from './chat-popup.css';
import { POPUP_TYPES } from './constants';

export default class ChatPopup {
  constructor() {
    this.ele = document.createElement('div');
    this.ele.classList.add(styles.popupContainer);
  }

  create = (type, config) => {
    const popupContainer = document.createElement('div');

    switch (type) {
      case POPUP_TYPES.video: {
        popupContainer.classList.add(styles.videoContainer);

        const videoEle = document.createElement('video');
        videoEle.src = config.mediaURL;
        videoEle.autoplay = true;
        videoEle.loop = true;
        videoEle.muted = true;
        videoEle.playsInline = true;
        popupContainer.append(videoEle);

        break;
      }

      case POPUP_TYPES.image: {
        popupContainer.classList.add(styles.imageContainer);
        const imageEle = document.createElement('img');
        imageEle.src = `${config?.mediaURL}?format=auto&w=360&h=640`;
        popupContainer.append(imageEle);
        break;
      }

      case POPUP_TYPES.text: {
        popupContainer.classList.add(styles.textContainer);
        popupContainer.innerText = config.text;
        popupContainer.style.backgroundColor = config.bgColor;
        popupContainer.style.borderColor = config.accentColor;
        popupContainer.style.color = config.textColor;
        break;
      }

      default:
        break;
    }

    // Delay popup display.........
    if (config?.timing > 0) {
      const delayTime = Number(config.timing) * 1000;
      popupContainer.classList.add(styles.hidePopup);

      setTimeout(() => {
        popupContainer.classList.add(styles.showPopup);
        popupContainer.classList.remove(styles.hidePopup);
      }, delayTime);
    }

    // Auto hide popup.................
    if (config?.autoHide && config?.hideTiming > 0) {
      const hideTime = Number(config.hideTiming) * 1000;

      setTimeout(() => {
        popupContainer.remove();
      }, hideTime);
    }

    this.ele.append(popupContainer);
    return popupContainer;
  };

  updateStyles = (styleProp) => {
    // chat popup bottom position = chat button size + chat button bottom position + spacing
    const bottom = styleProp.buttonSize + styleProp.bottom + 10;

    this.ele.style.bottom = toPixel(bottom);
    this.ele.style[styleProp.positionType] = toPixel(styleProp.position);

    const textPopupContainers = this.ele.querySelectorAll(
      '.akin__chat-popup__text-container'
    );

    if (textPopupContainers?.length > 0) {
      textPopupContainers.forEach((textEle) => {
        // eslint-disable-next-line no-param-reassign
        textEle.style.marginLeft =
          styleProp.positionType === 'right' ? 'auto' : 'unset';
      });
    }
  };

  resetStyles = () => {
    this.ele.style.left = 'unset';
    this.ele.style.right = 'unset';
  };

  hide = () => {
    this.ele.style.display = 'none';
  };
}
