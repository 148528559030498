import { toPixel } from './utils';
import styles from './chat-button.css';
import { DEFAULT_STYLE_PROPS } from './constants';

const ASSETS_BASE_URL = `${process.env.CDN_PATH}/assets`;

export default class ChatButton {
  constructor({ showOnInit = true, theme }) {
    // Stylesheet take some time to reflect on initial load of the widget button
    // Hence to avoid glitches, styles are directly applied to button container element.
    this.ele = document.createElement('div');
    this.ele.id = 'akin-chat-button';
    this.ele.style.position = 'fixed';
    this.ele.style.width = toPixel(DEFAULT_STYLE_PROPS.size);
    this.ele.style.height = toPixel(DEFAULT_STYLE_PROPS.size);
    this.ele.style.borderRadius = '50%';
    this.ele.style.bottom = toPixel(DEFAULT_STYLE_PROPS.verticalSpacing);
    this.ele.style.overflow = 'hidden';
    this.ele.style.backgroundColor = theme;
    this.ele.style.display = (!showOnInit && 'none') || 'block';
    this.ele.classList.add(styles.buttonContainer);
  }

  create = (config) => {
    if (config?.type === 'video') {
      const videoEle = document.createElement('video');
      videoEle.src = config?.url;
      videoEle.autoplay = true;
      videoEle.loop = true;
      videoEle.muted = true;
      videoEle.playsInline = true;
      this.ele.append(videoEle);
      return;
    }

    const chatIcon = document.createElement('img');
    chatIcon.alt = 'D3x chat widget';

    if (config?.url) {
      chatIcon.src = `${config.url}?format=auto&w=100&h=100`;
      chatIcon.classList.add(styles.icon);
    } else {
      chatIcon.src = `${ASSETS_BASE_URL}/chat.svg`;
      chatIcon.classList.add(styles.defaultIcon);
    }

    this.ele.append(chatIcon);
  };

  show = () => {
    if (!this.ele) return;
    this.ele.style.display = 'unset';
  };

  hide = () => {
    if (!this.ele) return;
    this.ele.style.display = 'none';
  };

  updateStyles = (styleProp) => {
    this.ele.style.bottom = toPixel(styleProp.bottom);
    this.ele.style[styleProp.positionType] = toPixel(styleProp.position);
    this.ele.style.width = toPixel(styleProp.width);
    this.ele.style.height = toPixel(styleProp.height);
  };

  resetStyles = () => {
    this.ele.style.left = 'unset';
    this.ele.style.right = 'unset';
  };

  destroy = () => {
    if (!this.ele) return;
    this.ele.remove();
  };
}
